import React, { JSX, useEffect }  from "react";

import { Navigate, Outlet, useParams } from "react-router-dom";

import { reactiveVarHelper } from "common/helpers";
import { Blocks, Menus } from "components/layout";
import { Loader } from "components/request-result";
import { ROUTES } from "components/routes";
import { useExhibitionEvent, useGraphicsSuppliers } from "components/use-hook";


const ExhibitionSection : React.FC = () : JSX.Element => {

    const rVar = reactiveVarHelper(),
          pParams = useParams(),
          isEeCreate = pParams.eeId === "create";

    const { exhibitionEvent, loading, error } = useExhibitionEvent({
        eeId : !isEeCreate ? pParams.eeId : undefined,
        fetchPolicy : 'cache-first',
        withStatus : true
    });

    const { loading : supplierLoading } = useGraphicsSuppliers({
        organizerId : !isEeCreate && exhibitionEvent ? exhibitionEvent.organizer_id : undefined,
        fetchPolicy : 'cache-first',
        withStatus : true
    });

    useEffect( () => {

        rVar.set({
            mainBlockClass : [ "wSidebar" ]
        });

        return () => {
            rVar.set({
                mainBlockClass : []
            });
        };
    });

    if((error || isNaN(Number(pParams.eeId)) && !isEeCreate) || (!exhibitionEvent && !isEeCreate && !loading)){
        return <Navigate to={ ROUTES.EXHIBITION_ITEM_CREATE } replace={ true } />;
    }

    return(
        <div className="section exhibition">
            { (loading || supplierLoading ) ? <Loader type={ "block" } /> :
                <>
                    <Blocks.Sidebar>
                        <Menus.Exhibition />
                    </Blocks.Sidebar>
                    <Outlet
                        context={{
                            ee : exhibitionEvent
                        }}
                    />
                </>
            }
        </div>
    );
};

export default ExhibitionSection;