import StandCadChangeCurrentModal from "./Stand-cad-change-current-modal";
import StandChangeTypeSizeModal from "./Stand-change-type-size-modal";
import StandCommentEditModal from "./Stand-comment-edit-modal";

import "./stand-modals.scss";


const StandModals = {
    CommentEdit : StandCommentEditModal,
    CadChangeStatus : StandCadChangeCurrentModal,
    ChangeTypeSize : StandChangeTypeSizeModal
};

export default StandModals;