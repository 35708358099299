import React, { JSX, useState } from "react";

import { TTaskComment } from "graphql/type/task-gql-types";

import TaskForms from "../forms";
import TaskLists from "../lists";

import type { TID } from "graphql/type/common-gql-types";


interface ITaskCommentsBlockProps {
    taskId : TID
}

const TaskCommentsBlock : React.FC<ITaskCommentsBlockProps> = ({ taskId }) : JSX.Element => {

    const [ comments, setComments  ] = useState<TTaskComment[]>([]);

    return (
        <div className="task-comments-block">
            <TaskLists.Comments
                taskId      = { taskId }
                comments    = { comments }
                setComments = { setComments }
            />
            <TaskForms.CommentEdit
                taskId = { taskId }
                comments    = { comments }
                setComments = { setComments }

            />
        </div>
    );
};

export default TaskCommentsBlock;