import React, { JSX, useState } from "react";

import { Button, FormInstance } from "antd";

import StandForms from "../forms";
import { Icons } from "components/layout";
import { ModalStandard } from "components/service";

import type { TStandTypeProps } from "../fields/Stand-type-select";
import type { TStandItem } from "graphql/type/stand-gql-types";
import type { TStandType } from "graphql/type/stand-type-gql-types";


interface IStandCadChangeCurrentProps {
    stand : TStandItem<TStandType>
    ee: any
    standType : TStandTypeProps
    callback : () => void
    btnClass   ?: string
}

const StandChangeTypeSizeModal: React.FC<IStandCadChangeCurrentProps> = (
    {
        btnClass = "",
        callback = () => {},
        stand,
        ee,
        standType
    }) : JSX.Element => {

    const [ openModal, setOpenModal ] = useState( false ),
          [ form, setForm ] = useState<FormInstance | undefined>(undefined);

    return(
        <>
            <Button
                type      = "text"
                className = { btnClass }
                onClick   = { () => setOpenModal(true) }
            >
                <Icons.Substitute /> Change
            </Button>
            <ModalStandard
                title      = { <h2>Change stand type and size</h2> }
                isOpen     = { openModal }
                extraClass = { "stand-change-size-type-modal" }
                close      = { () => {
                    setOpenModal(false);
                }}
                okText     = { 'Change' }
                confirm    = {
                    () => {
                        form && form.submit();
                    }
                }
            >
                <div className="warning">
                    <Icons.Importance  />
                    <span className="text">
                        These changes can effect default equipment list
                    </span>
                </div>
                <StandForms.ChangeTypeSize
                    standTypeInfo = {{
                        title : standType.label,
                        standId : stand.id,
                        groupId : standType.value,
                        eeOrganizerId : ee.organizer_id,
                        eeTypeId : ee.exhibition_type_id,
                    }}
                    size = {{
                        width : stand.size_width,
                        height : stand.size_height,
                        min : standType.min,
                        max : standType.max
                    }}
                    setForm     = { setForm }
                    onUpdate    = { () =>  {
                        setTimeout(
                            () => {
                                callback();
                                setOpenModal(false);
                            }
                        );

                    }}
                />
            </ModalStandard>
        </>
    );
};

export default StandChangeTypeSizeModal;