import React, { JSX }  from "react";

import { useQuery } from "@apollo/client";
import { Col, Row } from "antd";
import { useOutletContext } from "react-router-dom";

import { GET_GRAPHICS_ORDERS } from "graphql/query/graphics-q.gql";

import GraphicsModals from "../../../graphics/modals";
import Graphics from "components/graphics";
import { Loader } from "components/request-result";

import type { TStandItem } from "graphql/type/stand-gql-types";
import type { TStandType } from "graphql/type/stand-type-gql-types";


const StandGraphicsSubPage : React.FC = () : JSX.Element => {

    const { ee, stand } = useOutletContext<{
        ee : any,
        stand: TStandItem<TStandType>
    }>();

    const {
        data : { graphicsOrdersCursor  } = { },
        loading,
    } = useQuery( GET_GRAPHICS_ORDERS, {
        variables : {
            where : stand ? {
                column : "STAND_ID",
                operator : "EQ",
                value : stand.id,
            } : undefined,
            orderBy : [ { column : "ID", order : "ASC" } ]
        },
        fetchPolicy : 'cache-and-network'
    });

    const {
        edges : graphicsOrders = [],
        pageInfo
    } = graphicsOrdersCursor || {};
    
    return(
        <div className="page-content stand-graphics">
            { loading ?
                <Loader type="block" /> :
                <Row gutter={[ 24, 16 ]}>
                    <Col {...{
                        xxl : 18, xl : 24, lg : 24, span : 24
                    }}>
                        <Graphics.Blocks.PageActions
                            standId    = { stand.id }
                            totalOrder = { pageInfo?.total }
                        >
                            <GraphicsModals.OrderCreate
                                standId     = { stand.id }
                                organizerId = { ee.organizer_id }
                            />
                        </Graphics.Blocks.PageActions>
                    </Col>
                    <Col {...{
                        xxl : 12, xl : 16, lg : 18, span : 24
                    }}>
                        <Graphics.Blocks.Orders
                            standId        = { stand.id }
                            organizerId    = { ee.organizer_id }
                            graphicsOrders = { graphicsOrders }
                        />
                    </Col>
                    <Col {...{
                        xxl : 6, xl : 8, lg : 6, span : 24
                    }}>
                        { stand &&
                            <>
                                <Graphics.Blocks.ApprovalLayout
                                    standId  = { stand.id }
                                />
                            </>
                        }
                    </Col>
                </Row>
            }
        </div>

    );
};

export default StandGraphicsSubPage;