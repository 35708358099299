import React, { JSX, useEffect } from "react";

import { useQuery } from "@apollo/client";
import { TTaskComment } from "graphql/type/task-gql-types";

import { TASK_COMMENT_DELETE } from "graphql/mutation/task-m.gql";
import { GET_TASK_COMMENTS } from "graphql/query/task-q.gql";

import TaskLists from "./index";
import { getDate, whereConditionBuilder } from "common/utils";
import { Menus } from "components/layout";
import { Loader } from "components/request-result";
import { DeleteButton } from "components/service";

import { EDateType } from "common/types";
import type { TID } from "graphql/type/common-gql-types";


interface ITaskCommentListProps{
    taskId : TID
    comments: TTaskComment[]
    setComments: ( newComments : TTaskComment[] ) => void
}

const TaskCommentList : React.FC<ITaskCommentListProps> = (
    {
        taskId ,
        comments,
        setComments,
    }) : JSX.Element => {

    const { data , loading } = useQuery( GET_TASK_COMMENTS, {
        variables : {
            where : whereConditionBuilder({
                taskId : !taskId ? {} : {
                    column : "TASK_ID",
                    operator : "EQ",
                    value : taskId
                },
            }),
            orderBy : [ { column : "ID", order : "DESC" } ]
        },
        fetchPolicy : "cache-and-network"
    });

    const {
        taskCommentsCursor : {
            edges : taskComments = []
        } = {}
    } = data || {};

    useEffect(() => {
        if(!loading){
            setComments(
                taskComments.map(({ node }) => {
                    return { ...node };
                })
            );
        }
    }, [ data ]);

    return (
        <div className="task-comment-list-wrap">
            { loading && <Loader type="block" /> }
            { !!comments.length &&
                <ul className="task-comment-list">
                    { comments.map(
                        ({ id, relAuthor, description, created_at, relAttachments }) => {

                            return (
                                <li className="task-comment-list-item" key={ id }>
                                    <div className="row comment-info">
                                        <div className="author">{ relAuthor.name } { relAuthor.surname }</div>
                                        <div className="action">
                                            <div className="date">
                                                {getDate.formated(created_at, EDateType.MONTHDAYTIME)}
                                            </div>
                                            <Menus.Popover>
                                                <></>
                                                <DeleteButton
                                                    className  = { "task-comment-delete" }
                                                    buttonType = { "text" }
                                                    id         = { Number(id) }
                                                    query      = { TASK_COMMENT_DELETE }
                                                    onSuccess  = { (_, cache) => {
                                                        cache.modify({
                                                            id : cache.identify({ __ref : `Task:${ taskId }` }),
                                                            fields : {
                                                                total_comment(currentCount) {
                                                                    return currentCount - 1;
                                                                },
                                                            },
                                                        });

                                                        setComments(
                                                            comments.filter( (comment) => comment.id !== id )
                                                        );
                                                    }}
                                                    confirmText= { `Do you want to delete this comment?` }
                                                >
                                                    Delete
                                                </DeleteButton>
                                            </Menus.Popover>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="comment-text">
                                            { description }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <TaskLists.Files
                                            files     = { relAttachments }
                                            linkClass = { 'inverted' }
                                        />
                                    </div>
                                </li>
                            );
                        })
                    }
                </ul>
            }
        </div>
    );
};

export default TaskCommentList;