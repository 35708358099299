import React, { JSX, useState } from "react";

import { Form } from "antd";

import TaskButtons from "../buttons";
import TaskForms from "../forms";
import ModalStandard from "components/service/Modal-standard";

import type { TID } from "graphql/type/common-gql-types";
import type { TTask } from "graphql/type/task-gql-types";


interface StandCommentEditModalProps {
    standId   : TID
    teamId    : TID
    task     ?: TTask
}

const TaskEditModal : React.FC<StandCommentEditModalProps> = ({
        standId,
        teamId,
        task,
    }) : JSX.Element => {

    const isTaskEdit = !!task;

    const [ taskState, setTaskState ] = useState<TTask | undefined>( task );
    const [ openModal, setOpenModal ] = useState( false );
    const [ form ] = Form.useForm();

    return (
        <>
            <TaskButtons.Edit
                standId      = { standId }
                setOpenModal = { setOpenModal }
                setTaskState = { setTaskState }
                isCreate     = { !isTaskEdit }
                btnType      = { isTaskEdit ? "text" : "primary" }
            />
            <ModalStandard
                title      = { <h2>{ task && task.status !== 'draft' ? 'Edit task' : 'Add task' }</h2> }
                isOpen     = { openModal }
                extraClass = { "task-edit-modal" }
                close      = { () => {
                    setOpenModal(false);
                }}
                forceRender= { true }
                okText     = { task && task.status !== 'draft' ? 'Save task' :  'Add task' }
                confirm    = {
                    () => {
                        form.submit();
                    }
                }
            >
                { taskState &&
                    <TaskForms.Edit
                        standId      = { standId }
                        teamId       = { teamId }
                        form         = { form }
                        task         = { taskState }
                        setTaskState = { setTaskState }
                        onUpdate     = { (callback ?: () => void) => {
                            setOpenModal(false);
                            callback && callback();
                        }}
                    />
                }
            </ModalStandard>
        </>

    );
};

export default TaskEditModal;