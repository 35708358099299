import React, { JSX, useEffect } from "react";

import { useMutation } from "@apollo/client";
import { Form, FormInstance } from "antd";

import { GRAPHICS_ORDER_CREATE } from "graphql/mutation/graphics-m.gql";

import GraphicsFields from "../fields";
import useGraphicsSupplierFragment from "../hooks/useGraphicsSupplierFragment";
import { useNotification } from "components/use-hook";

import type { TID } from "graphql/type/common-gql-types";

interface IStandCadStatusFormProps {
    standId      : TID
    organizerId  : TID
    setForm     ?: (form :  FormInstance) => void
    onUpdate    ?: (callback ?: () => void) => void
}

const GraphicsOrderCreateForm : React.FC<IStandCadStatusFormProps> = (
    {
        standId,
        organizerId,
        setForm,
        onUpdate = () => {}
    }) : JSX.Element => {

    const { nError, nSuccess } = useNotification();

    const [ graphicsOrderCreate, { loading } ] = useMutation(
        GRAPHICS_ORDER_CREATE,
        {
            update(cache, { data }) {

                const { 'graphicsOrderCreate' : {
                    label,
                    message,
                }  } = data;

                nSuccess(label, message);
                onUpdate();
            },
            onError(error) {
                console.error(error);
            },
            refetchQueries : [ 'GetGraphicsOrders' ]
        }
    );

    const [ form ] = Form.useForm();

    useEffect(() => {
        if(setForm){
            setForm(form);
        }
    }, []);

    const supplierId : TID = Form.useWatch<string, FormInstance>('graphic_supplier_id', form),
          { relRule } = useGraphicsSupplierFragment( { supplierId }) || {};

    return(
        <div className="graphics-order-create-form">
            <Form
                className     = { "inner-forms" }
                form          = { form }
                layout        = "vertical"
                //initialValues = {}
                onFinish={(values) => {
                    if(!loading){

                        const productType = relRule?.find(
                            ( rule ) => rule.id === values.product_type
                        );

                        graphicsOrderCreate({
                            variables : {
                                input : {
                                    ...values,
                                    stand_id : standId ,
                                    status : 'graphic_status_new_mp',
                                    quantity : 1,
                                    price : productType?.price_manufacture || 0
                                }
                            }
                        }).catch(nError);
                    }
                }}
            >
                <Form.Item
                    label = {"Rule set"}
                    name  = "graphic_supplier_id"
                    rules = {[ {
                        required : true,
                        message : "required"
                    } ]}
                >
                    <GraphicsFields.SupplierSelect
                        organizerId = { organizerId }
                    />
                </Form.Item>
                <Form.Item
                    label = {"Product type"}
                    name  = "product_type"
                    rules = {[ {
                        required : true,
                        message : "required"
                    } ]}
                >
                    <GraphicsFields.ProductTypeSelect
                        supplierId = { supplierId }
                        disabled   = { !supplierId }
                    />
                </Form.Item>

            </Form>
        </div>
    );
};

export default GraphicsOrderCreateForm;