import React, { JSX } from 'react';

import { useQuery } from "@apollo/client";
import { Table } from 'antd';

import { GET_GRAPHICS_ORDERS } from "graphql/query/graphics-q.gql";

import graphicsOrdersMock from "../../../mock/graphics.mock";
import GraphicsHelpers from "../helpers";
import { whereConditionBuilder } from "common/utils";
import { Loader } from "components/request-result";

import type { TFilterFields } from "common/types";
import type { TID } from "graphql/type/common-gql-types";


type IGraphicsTableProps = {
    standId ?: TID
    eeId     : TID
    filters  : TFilterFields
};

const GraphicsOrdersTable : React.FC<IGraphicsTableProps> = ({ filters, eeId }) : JSX.Element  => {


    const { data : { graphicsOrdersCursor } = { },
        loading,
        // error
    } = useQuery( GET_GRAPHICS_ORDERS, {
        variables : {
            text : filters.text,
            where : whereConditionBuilder({
                teamRole : !eeId ? {} : {
                    column : "EE_ID",
                    operator : "EQ",
                    value : eeId
                },
            }),
            orderBy : [ { column : "ID", order : "DESC" } ]
        },
        fetchPolicy : "cache-and-network"
    });

    const { edges : graphicsOrders = [] } = graphicsOrdersCursor || {};

    const { columns, dataMap, totalColumnWidth } = GraphicsHelpers.table;

    return(
        <div className="graphics-orders-table-wrap">
            { (graphicsOrders.length || graphicsOrdersMock) &&
                <Table
                    className   = "graphics-orders-table"
                    columns     = { columns! }
                    dataSource  = { dataMap( /*graphicsOrders.length ? graphicsOrders :*/ graphicsOrdersMock ) }
                    scroll      = {{ x : totalColumnWidth() }}
                    pagination  = { false }
                />
            }
            { loading && <Loader type={ "block" } /> }
        </div>
    );
};

export default GraphicsOrdersTable;