import GraphicsApprovalLayoutBlock from "./Graphics-approval-layout-block";
import GraphicsOrderCollapseHeaderBlock from "./Graphics-order-collapse-header-block";
import GraphicsOrderPageActionsBlock from "./Graphics-order-page-actions-block";
import GraphicsOrdersBlock from "./Graphics-orders-block";

import "./graphics-blocks.scss";


const GraphicsBlocks = {
    ApprovalLayout : GraphicsApprovalLayoutBlock,
    PageActions : GraphicsOrderPageActionsBlock,
    OrderCollapseHeader : GraphicsOrderCollapseHeaderBlock,
    Orders : GraphicsOrdersBlock
};

export default GraphicsBlocks;