import React, { JSX } from "react";

import { TID } from "graphql/type/common-gql-types";


interface GraphicsOrderPageActionsBlockProps {
    standId     : TID
    totalOrder ?: number
    children   ?: React.ReactNode
}

const GraphicsOrderPageActionsBlock : React.FC<GraphicsOrderPageActionsBlockProps> = (
    {
        standId,
        totalOrder = 0,
        children
    }) : JSX.Element => {

    console.log(standId);
    
    return (
        <div className="graphics-order-page-actions">
            <h3>There are { totalOrder } graphics order</h3>
            <div className="actions">
                { children }
            </div>
        </div>
    );
};

export default GraphicsOrderPageActionsBlock;