import React, { JSX } from "react";

import { Blocks } from "components/layout";

import type { TID } from "graphql/type/common-gql-types";


interface IGraphicsApprovalLayoutBlockProps {
    standId     : TID
}

const GraphicsApprovalLayoutBlock : React.FC<IGraphicsApprovalLayoutBlockProps> = ({ standId }) : JSX.Element => {

    console.log('GraphicsApprovalLayoutBlock', standId);

    return(
        <Blocks.Card
            Header    = { "Approval layout" }
            className = { "graphics-approval-layout-block" }
        >
            <div className="no-data">
                <p className="label">No available Layouts yet</p>
            </div>
        </Blocks.Card>
    );
};

export default GraphicsApprovalLayoutBlock;