import React, { JSX, ReactNode } from "react";

import { Button, Popover } from "antd";

import { Icons } from "components/layout";


const PopoverMenuHolder : React.FC<{ children : ReactNode }> = ({ children }) : JSX.Element  => {

    return(
        <Popover
            className = "popover-menu-holder"
            placement = "bottomRight"
            content   = { children }
            trigger   = { [ "click" ] }
            align     = {{ offset : [ 3, 2 ] }}
            //onOpenChange = { (open) => { console.log(open); } }
        >
            <Button type="text" className={ "popover-btn" }>
                <Icons.ThreeDot />
            </Button>
        </Popover>
    );
};

export default PopoverMenuHolder;