import React, { JSX, useEffect, useState } from "react";

import { Button, Upload, UploadProps } from "antd";
//import { RcFile } from "antd/es/upload/interface";

import TaskLists from "../lists";
import { Icons } from "components/layout";

import type { TAttachment } from "graphql/type/common-gql-types";


interface ITaskCommentAttachFileFieldProps {
    extraClass ?: string
    accept     ?: string
    file       ?: TAttachment
    onChange   ?: (file : any) => void
}

// const getBase64 = (
//         file : RcFile,
//         callback : (url: string) => void
//     ) => {
//
//     const reader = new FileReader();
//
//     reader.addEventListener('load', () => {
//         if(typeof reader.result === 'string'){
//             callback(reader.result);
//         }
//     });
//
//     reader.readAsDataURL(file);
// };

const TaskCommentAttachFileField: React.FC<ITaskCommentAttachFileFieldProps> = ({
        extraClass = "",
        accept = '*/*',
        file = undefined,
        ...props
    }) : JSX.Element => {

    const [ fileState, setFileState ] = useState<TAttachment | undefined>(file);

    useEffect(() => {
        return () => {
            if(fileState){
                URL.revokeObjectURL(fileState.path);
            }
        };
    }, []);

    const onChange: UploadProps['onChange'] = ({ file  }) => {
        if(file && file.originFileObj){

            setFileState({
                id : file.uid,
                server_name : file.name,
                original_name : file.name.split('.').shift(),
                path : URL.createObjectURL(file.originFileObj as Blob),
                extension : file.name.split('.').pop(),
            } as unknown as TAttachment);
        }

        props.onChange && props.onChange(file);
    };

    const uploadProps = {
        ...props,
        className : `${extraClass}`,
        accept : accept,
        customRequest : () => {
            return false;
        },
        showUploadList : false,
        multiple : false,
        onChange
    };

    return(
        <div className="task-comment-attach-file">
            { fileState ?
                <TaskLists.Files
                    files      = { [ fileState ] }
                    withDelete = { true }
                    onDelete   = { () => setFileState(undefined) }
                    noRequest  = { true }
                /> :
                <Upload {...uploadProps}>
                    <Button
                        className = "attach-file inverted"
                        type      = "text"
                    >
                        <Icons.Attach /> Attach file
                    </Button>
                </Upload>
            }
        </div>
    );
};


export default TaskCommentAttachFileField;