import standCadHelpers from "./stand-cad-helper";
import standCommentMapHelper from "./stand-comment-map-helper";
import { prepareEditFormDataHelper, prepareEditInitDataHelper } from "./stand-form-edit-helper";
import { standOrientations } from "./stand-orientations-helper";
import standsTableHelper from "./stand-table-helper";


const StandHelpers = {
    editForm : {
        prepareData : prepareEditFormDataHelper,
        initialData : prepareEditInitDataHelper
    },
    orientations : standOrientations,
    table : standsTableHelper,
    commentMap : standCommentMapHelper,
    cad : standCadHelpers
};

export default StandHelpers;
