import TaskCadUploadField from "./Task-cad-upload-field";
import TaskCommentAttachFileField from "./Task-comment-attach-file-field";
import TaskImportanceSelect from "./Task-importance-select";
import TaskStatusSelect from "./Task-status-select";
import TaskTypeSelect from "./Task-type-select";

import "./Task-fields.scss";


const TaskFields = {
    Importance : TaskImportanceSelect,
    Status : TaskStatusSelect,
    Type : TaskTypeSelect,
    CommentFile : TaskCommentAttachFileField,
    CadUpload : TaskCadUploadField
};

export default TaskFields;