import React, { JSX } from "react";

import { TagStandard } from "../../service";
import useGraphicsSupplierFragment from "../hooks/useGraphicsSupplierFragment";
import { Icons } from "components/layout";
import Localize from "components/service/Localize";

import type { TGraphicsOrder } from "graphql/type/graphics-gql-types";


interface IGraphicsOrdersBlockProps {
    graphicsOrder : TGraphicsOrder
}

const GraphicsOrderCollapseHeaderBlock : React.FC<IGraphicsOrdersBlockProps> = (
    {
        graphicsOrder : {
            number : orderNumber,
            status,
            actual_height,
            actual_width,
            product_type,
            material,
            graphic_supplier_id : supplierId
        },
    }) : JSX.Element => {

    const { relRule } = useGraphicsSupplierFragment( { supplierId }) || {};
    const productType = relRule?.find((  { id } ) => id === product_type );

    return (
        <div className="graphics-order-collapse-header">
            <div className="order-number-wrap">
                №{ orderNumber }
            </div>
            <div className="order-status-wrap">
                <TagStandard className = { 'new' }>
                    <Localize>{`VARIABLE_ITEM.${ status }`}</Localize>
                </TagStandard>
            </div>
            <div className="order-info-wrap">
                <div className="dimensions">
                    <p><span>W:</span> {actual_width || 0}mm</p>
                    <p><span>H:</span> {actual_height || 0}mm</p>
                </div>

                {(product_type || material) &&
                    <div className="product-info">
                        { productType &&
                            <p className="type">
                                { productType.title  }
                            </p>
                        }
                        { material &&
                            <Localize wrap tag="p" className="material">
                                {`VARIABLE_ITEM.${ material }`}
                            </Localize>
                        }
                    </div>
                }
            </div>
            <div className="print-data-wrap">
                <Icons.Attach />
                Print data
            </div>
        </div>
    );
};


export default GraphicsOrderCollapseHeaderBlock;