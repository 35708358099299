import GraphicsOrderCreateForm from "./Graphics-order-create-form";
import GraphicsOrderEditForm from "./Graphics-order-edit-form";

import "./graphics-forms.scss";

const GraphicsForms = {
    OrderCreate : GraphicsOrderCreateForm,
    OrderEdit : GraphicsOrderEditForm,
};

export default GraphicsForms;