import { gql, TypedDocumentNode } from '@apollo/client';

import { IQueryVariables } from "../type/common-gql-types";

import type { TStandCADSCursorData, TStandCommentsCursorData, TStandCursorData, TStandData } from "../type/stand-gql-types";


export const GET_STAND: TypedDocumentNode<{ stand : TStandData }, { id : number | string } > = gql`
    query GetStand ( $id : ID! ){
        stand(id: $id) {
            id
            ee_id
            company_name
            hall_id
            hall_title
            block
            stand_number
            proauf
            orientation_key
            size_width
            size_height
            square_meter
            flooring_id
            flooring_title
            stand_type_group
            relStandType {
                id
                title
                group_id
                lang
                navision_number
                size_min
                size_max
                price
                complexity
                orientations{
                    id
                    img_path
                    orientation_key
                }
                getImages {
                    id
                    title
                    server_name
                    sizes
                }
                meta_fields {
                    id
                    stand_type_id
                    meta_key
                    meta_value
                    meta_group
                }
            }
            project_manager_id
            relProjectManager {
                id
                name
                surname
            }
            autocad_engineer_id
            relAutocadEngineer {
                id
                name
                surname
            }
            relCad{
                id
                cad_number
                status
                version
                correction
                file_path
                updated_at
                created_at
            }
            relStandCommentEquipment {
                id
                comment
                relUser {
                    id
                    name
                    surname
                }
            }
            relTasks {
                id
                title
                status
                importance
                type
                relAssignedTo {
                    id
                    name
                    surname
                }
                updated_at
                created_at
            }
            total_comment
            created_at
            updated_at
        }
    }
`;

export const GET_STANDS: TypedDocumentNode<{ standsCursor : TStandCursorData }, IQueryVariables > = gql`
    query GetStands (
        $text    : String
        $where   : QueryStandsCursorWhereWhereConditions
        $orderBy : [QueryStandsCursorOrderByOrderByClause!]
        $after   : String
    ){
        standsCursor(
            text    : $text
            where   : $where
            orderBy : $orderBy
            first   : 50
            after   : $after
        ) {
            pageInfo {
                hasNextPage
                startCursor
                endCursor
                total
                currentPage
            }
            edges {
                node{
                    id
                    ee_id
                    company_name
                    hall_id
                    hall_title
                    block
                    stand_number
                    proauf
                    stand_type_group
                    orientation_key
                    size_width
                    size_height
                    square_meter
                    flooring_id
                    flooring_title
                    project_manager_id
                    relStandType {
                        id
                        title
                        lang
                        orientations{
                            id
                            img_path
                            orientation_key
                        }
                    }
                    relProjectManager {
                        id
                        name
                        surname
                    }
                    autocad_engineer_id
                    relAutocadEngineer {
                        id
                        name
                        surname
                    }
                    relStandEquipmentOrders {
                        id
                        qty
                        price
                        set_type
                        equipment_variation_id
                        relEquipment {
                            id
                            group_id
                            title
                            lang
                            updated_at
                            created_at
                        }
                        relEquipmentVariation {
                            id
                            title
                            organizer_id
                            article
                            price
                        }
                        created_at
                        updated_at
                    }
                    relStandCommentEquipment {
                        id
                        comment
                        relUser {
                            id
                            name
                            surname
                        }
                    }
                    relCad{
                        id
                        cad_number
                        status
                        version
                        correction
                        file_path
                        updated_at
                        created_at
                    }
                    relTasks {
                        id
                        title
                        status
                        importance
                        type
                        relAssignedTo {
                            id
                            name
                            surname
                        }
                        updated_at
                        created_at
                    }
                    total_comment
                    created_at
                    updated_at
                }
            }
        }
    }
`;


export const GET_STAND_COMMENTS: TypedDocumentNode<{ standCommentsCursor : TStandCommentsCursorData }, IQueryVariables > = gql`
    query GetStandComments (
        $text    : String
        $where   : QueryStandCommentsCursorWhereWhereConditions
        $orderBy : [QueryStandCommentsCursorOrderByOrderByClause!]
        $after   : String
    ){
        standCommentsCursor(
            text    : $text
            where   : $where
            orderBy : $orderBy
            first   : 50
            after   : $after
        ) {
            pageInfo {
                hasNextPage
                startCursor
                endCursor
                total
                currentPage
            }
            edges {
                node {
                    id
                    stand_id
                    type
                    comment
                    user_id
                    relUser {
                        id
                        name
                        surname
                    }
                    created_at
                    updated_at
                }
                cursor
            }
        }
    }
`;


export const GET_CADS: TypedDocumentNode<{ cadsCursor : TStandCADSCursorData }, IQueryVariables > = gql`
    query GetCads (
        $text    : String
        $where   : QueryCadsCursorWhereWhereConditions
        $orderBy : [QueryCadsCursorOrderByOrderByClause!]
        $after   : String
    ){
        cadsCursor(
            text    : $text
            where   : $where
            orderBy : $orderBy
            first   : 50
            after   : $after
        ) {
            pageInfo {
                hasNextPage
                startCursor
                endCursor
                total
                currentPage
            }
            edges {
                node{
                    id
                    ee_id
                    cad_number
                    status
                    version
                    correction
                    file_path
                    updated_at
                    created_at
                }
            }
        }
    }
`;
