import React, { JSX } from "react";

import { useQuery } from "@apollo/client";

import { GET_TASK } from "graphql/query/task-q.gql";

import TaskCommentsBlock from "./Task-comments-block";
import TaskFields from "../fields";
import TaskForms from "../forms";
import TaskHelpers from "../helpers";
import TaskLists from "../lists";
import TaskMenus from "../menus";
import { getDate } from "common/utils";
import { Loader } from "components/request-result";
import Localize from "components/service/Localize";

import { EDateType } from "common/types";
import type { TID } from "graphql/type/common-gql-types";


interface TaskBlockProps{
    taskId  : TID
    teamId  : TID
    eeId    : TID
    standId : TID
}

const TaskViewBlock : React.FC<TaskBlockProps> = ({
        taskId,
        teamId,
        standId,
        eeId
    }) : JSX.Element => {

    const { data : { task } = {} , loading } = useQuery( GET_TASK, {
        variables : {
            id : taskId,
        },
        fetchPolicy : "cache-and-network",
    });

    if(loading){
        return <Loader type="block" />;
    }

    return (
        <div className="task-view-block">
            { task &&
                <>
                    <div className="info">
                        <p className="text">
                            <span>
                                Created on { getDate.formated(task.created_at, EDateType.MONTHDAYTIME) }
                            </span>
                            { !getDate.compare({
                                    currentDate : task.created_at,
                                    searchDate : task.updated_at,
                                    unit : 'hour'
                                }) &&
                                <span>; Last update { getDate.formated(task.updated_at, EDateType.MONTHDAYTIME) }</span>
                            }
                        </p>
                        <TaskMenus.Item
                            standId = { standId }
                            teamId  = { teamId }
                            eeId    = { eeId }
                            task    = { task }
                        />
                    </div>
                    <h3 className="title">{task.title}</h3>
                    <div className="parameters grid-two-col">
                        <div className="status-field">
                            <h5 className="label">Status</h5>
                            <TaskForms.Status
                                taskId = { task.id }
                                status = { task.status }
                            />
                        </div>
                        <div>
                            <h5 className="label">Importance</h5>
                            <TaskHelpers.Importance
                                name     = { task.importance }
                                onlyIcon = {false}
                            />
                        </div>
                    </div>
                    <div className="parameters grid-two-col">
                        <div>
                            <h5 className="label">Task type</h5>
                            <Localize wrap className="type text" tag="p">
                                {`VARIABLE_ITEM.${ task.type }`}
                            </Localize>
                        </div>
                        {task.cad_number &&
                            <div>
                                <h5 className="label">CAD number</h5>
                                <p className="text">{ task.cad_number }</p>
                            </div>
                        }
                    </div>
                    <div className="parameters grid-two-col">
                        <div>
                            <h5 className="label">Created by</h5>
                            <p className="text">{ task.relCreator.name } { task.relCreator.surname }</p>
                        </div>
                        { task.relAssignedTo &&
                            <div>
                                <h5 className="label">Assigned to</h5>
                                <p className="text">{ task.relAssignedTo.name } { task.relAssignedTo.surname }</p>
                            </div>
                        }
                    </div>

                    { task.description &&
                        <div className="description">{task.description }</div>
                    }
                    <TaskLists.Files
                        files      = { task.relAttachments }
                        linkClass  = { "inverted" }
                    />
                    <TaskFields.CadUpload
                        cad={ task.relCad }
                    />
                    <TaskCommentsBlock
                        taskId = { task.id }
                    />
                </>
            }
        </div>
    );
};

export default TaskViewBlock;