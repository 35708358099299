import React, { JSX, ReactNode } from "react";

import { ApolloError } from "@apollo/client";
import { WatchQueryFetchPolicy } from "@apollo/client/core/watchQueryOptions";

import type { TAttachment, TID } from "graphql/type/common-gql-types";
import type { TUserRelation } from "graphql/type/user-gql-types";


// export interface IComponentsContainer {
//     [ key : string ] : FunctionComponent
// }

export type TTranslations = {
    [ key : string ] : string
};

export type TFilterFields = {
    text : string
    [ key : string ] : any
};

export type TLocales = "en" | "de";

export type TTranslationsContainer = {
    [ key in TLocales ] : TTranslations
};

export interface OutsideControlledModal {
    title           : string | ReactNode
    isOpen          : boolean
    close           : (callback ?: () => void ) => void
    confirm         : (props ?: any, callback ?: () => void ) => void
    okText         ?: string
    extraClass     ?: string
    destroyOnClose ?: boolean
    forceRender    ?: boolean
    children       ?: ReactNode
}

export interface OutsideControlledDrawer {
    title       : string | ReactNode
    isOpen      : boolean
    close       : (callback ?: () => void ) => void
    extra      ?: JSX.Element
    extraClass ?: string
    children   ?: ReactNode
}

export const enum ETeamRoles {
    PROJECT_MANAGER      = "project_manager",
    CONSTRUCTION_MANAGER = "construction_manager",
    DRAFTSMEN            = "draftsmen"
}

export const enum EOrganizerOrETStatus  {
    CLOSED = "closed",
    ACTIVE = "active"
}

export const enum EFileUploadModel {
    HALL          = "Hall",
    VARIABLE_LIST = "VariableList",
    FLOORING      = "Flooring",
    EQUIPMENT     = "Equipment",
    STAND_TYPE    = "StandType",
    ORGANIZER     = "Organizer",
    TASK          = "Task",
    CAD           = "Cad"
}

export const enum EFileUploadModelField {
    IMG_PATH  = "img_path",
    LOGO_PATH = "logo_path",
    HALL_PLAN = "hall_plan",
    FILE_PATH = "file_path",
}

export type TRouteParams = { [key : string] : any } | undefined;

export interface IErrorNotification{
    label         ?: string
    reason        ?: string
    graphQLErrors ?: [{
        message : string
        extensions : {
            reason : string | undefined
        }
    }]
}

export type TErrorNotification = ApolloError | undefined;

export interface ISuccessNotification{
    title        : string
    description ?: string
    duration    ?: number
}

export interface IDateTimeProp{
    withTime      ?: boolean
    withDay       ?: boolean
    allowClear    ?: boolean
    fromToday     ?: boolean
    placeHolder   ?: string
    inputReadOnly ?: boolean
    defaultValue  ?: any
    format        ?: EDateType
    onBlur        ?: (e : React.ChangeEvent<HTMLInputElement>) => void
}

export interface IMessageItem {
    id           : TID
    message      : string
    status      ?: string
    attachments ?: TAttachment[]
    user         : TUserRelation
    action      ?: JSX.Element | null
    created_at   : string
}

export enum EDateType {
    'DATE'     = 'date',
    'TIME'     = 'time',
    'DATETIME'    = 'datetime',
    'DAYDATE'     = 'daydate',
    'DAYDATETIME'      = 'daydatetime',
    'MONTHDAYYEARTIME' = 'monthdayyeartime',
    'MONTHDAYTIME'     = 'monthdaytime',
}

export interface IUseGraphQLHook {
    loading ?: boolean
    error   ?: ApolloError
}

export interface IUseGraphQLHookProps {
    fetchPolicy ?: WatchQueryFetchPolicy,
    withStatus  ?: boolean,
}

export interface IFilterOption {
    value: number,
    label: JSX.Element | string
    filterFields ?: string[]
}