import React, { JSX, useState } from "react";

import { useOutletContext } from "react-router-dom";

import Graphics from "components/graphics";
import { Blocks, Fields } from "components/layout";

import type { TFilterFields } from "common/types";


const GraphicListPage : React.FC = () : JSX.Element => {

    const { ee } = useOutletContext<{ee : any}>();
    const [ filters, setFilters ] = useState<TFilterFields>({ text : "" });

    return(
        <div className="page check-list-page">
            <Blocks.PageHeader
                title={ `${ ee.title } graphic list`}
            />
            <div className="page-content-wrap">
                <Blocks.TableHeader
                    filters = {<>
                        <Fields.Search
                            filters={ filters }
                            setFilters={ setFilters }
                        />
                    </>}
                />
                <Graphics.Tables.Orders
                    eeId    = { ee.id }
                    filters = { filters }
                />
            </div>
        </div>
    );
};

export default GraphicListPage;