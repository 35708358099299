import translation from "translations";

import { reactiveVarHelper } from "common/helpers";

import type { TLocales, TTranslations } from "common/types";
import type { TVariableItem } from "graphql/type/common-gql-types";
import type { TUser } from "graphql/type/user-gql-types";


type TRVarProps = {
    me : TUser,
    defaultLanguage : TLocales,
    variableLists : TVariableItem[]
};

const useTranslation = () : TTranslations => {

    const rVar = reactiveVarHelper(),
          { me, defaultLanguage, variableLists } : TRVarProps = rVar.get('all');


    if(me){
        document.documentElement.setAttribute("lang", me.lang_app);

        const additionalTranslations = {} as TTranslations;

        variableLists?.forEach((item) => {
            additionalTranslations[`VARIABLE_ITEM.${ item.slug }`] = item.value;
        });

        return {
            ...translation[ defaultLanguage ],
            ...translation[ me.lang_app ],
            ...additionalTranslations
        };
    }

    return { ...translation[ defaultLanguage ] };
};

export default useTranslation;