//import StandButtons from "./buttons";
import StandBlocks from "./blocks";
import StandFields from "./fields";
import StandForms from "./forms";
import StandHelpers from "./helpers";
import StandLists from "./lists";
import StandMenus from "./menus";
import StandModals from "./modals";
import StandTables from "./tables";


const Stand  = {
    Forms : StandForms,
    Blocks : StandBlocks,
    Fields : StandFields,
    Menus : StandMenus,
    //Buttons : StandButtons,
    Modals : StandModals,
    Helpers : StandHelpers,
    Tables : StandTables,
    Lists : StandLists,
};

export default Stand;