import { gql } from '@apollo/client';


export const STAND_CREATE = gql`
    mutation StandCreate($input: StandFieldsInput!) {
        standCreate(input: $input){
            label
            message
            stand {
                id
                ee_id
                company_name
                hall_id
                hall_title
                block
                stand_number
                proauf
                stand_type_group
                orientation_key
                size_width
                size_height
                square_meter
                flooring_id
                flooring_title
                project_manager_id
                relStandType {
                    id
                }
                relProjectManager {
                    id
                }
                autocad_engineer_id
                relAutocadEngineer {
                    id
                }
                relCad {
                    id
                }
                total_comment
                created_at
                updated_at
            }
        }
    }
`;

export const STAND_UPDATE = gql`
    mutation StandUpdate($input: StandFieldsInput!) {
        standUpdate(input: $input){
            label
            message
            stand {
                id
                ee_id
                company_name
                hall_id
                hall_title
                block
                stand_number
                proauf
                stand_type_group
                orientation_key
                size_width
                size_height
                square_meter
                flooring_id
                flooring_title
                project_manager_id
                relStandType {
                    id
                    title
                    group_id
                    lang
                    navision_number
                    size_min
                    size_max
                    price
                    complexity
                    orientations{
                        id
                        img_path
                        orientation_key
                    }
                    getImages {
                        id
                        title
                        server_name
                        sizes
                    }
                    meta_fields {
                        id
                        stand_type_id
                        meta_key
                        meta_value
                        meta_group
                    }
                }
                relProjectManager {
                    id
                    name
                    surname
                }
                autocad_engineer_id
                relAutocadEngineer {
                    id
                    name
                    surname
                }
                relStandCommentEquipment {
                    id
                    comment
                    relUser {
                        id
                        name
                        surname
                    }
                }
                total_comment
                created_at
                updated_at
            }
        }
    }
`;

export const STAND_DELETE = gql`
    mutation standDelete($id: ID!) {
        standDelete(id: $id){
            label
            message
        }
    }
`;

export const STAND_COMMENT_CREATE = gql`
    mutation StandCommentCreate($input: StandCommentFieldsInput!) {
        standCommentCreate(input: $input){
            label
            message
            standComment {
                id
                stand_id
                type
                comment
                user_id
                relUser {
                    id
                    name
                    surname
                }
                created_at
                updated_at
            }
        }
    }
`;

export const STAND_COMMENT_UPDATE = gql`
    mutation StandCommentUpdate($input: StandCommentFieldsInput!) {
        standCommentUpdate(input: $input){
            label
            message
            standComment {
                id
                stand_id
                type
                comment
                user_id
                relUser {
                    id
                    name
                    surname
                }
                created_at
                updated_at
            }

        }
    }
`;

export const STAND_COMMENT_DELETE = gql`
    mutation standCommentDelete($id: ID!) {
        standCommentDelete(id: $id){
            label
            message
        }
    }
`;

export const STAND_SIZE_TYPE_UPDATE = gql`
    mutation StandChangeTypeOrSize($input: StandFieldsTypeSizeInput!) {
        standChangeTypeOrSize(input: $input){
            label
            message
            stand {
                id
                orientation_key
                size_height
                size_width
                relStandType {
                    id
                    title
                    group_id
                    lang
                    navision_number
                    size_min
                    size_max
                    price
                    complexity
                    orientations{
                        id
                        img_path
                        orientation_key
                    }
                    getImages {
                        id
                        title
                        server_name
                        sizes
                    }
                    meta_fields {
                        id
                        stand_type_id
                        meta_key
                        meta_value
                        meta_group
                    }
                }
                created_at
                updated_at
            }
        }
    }
`;

export const STAND_CAD_UPDATE = gql`
    mutation StandCadUpdate($input: StandCadFieldsInput!) {
        standCadChange (input: $input){
            label
            message
            stand {
                id
                relCad {
                    id
                    cad_number
                    status
                    version
                    correction
                    file_path
                    updated_at
                    created_at
                }
                created_at
                updated_at
            }

        }
    }
`;

export const STAND_CAD_UPLOAD = gql`
    mutation fileUploadModel($input: FileUploadInput!) {
        fileUploadModel(input: $input){
            label
            message
            model {
                ... on Cad{
                    id
                    cad_number
                    status
                    version
                    correction
                    file_path
                    updated_at
                    created_at
                }
            }
        }
    }
`;