import type { TNode } from "graphql/type/common-gql-types";
import type { TStandCad } from "graphql/type/stand-gql-types";


type TCadListItem = {
    cad      : {
        version : TStandCad["version"]
        cadNumber : TStandCad['cad_number']
        count : number
    }
    versions : TStandCad[]
};

const cadListMap = (cadNodes : TNode<TStandCad>[]) : TCadListItem[] => {

    const existedVersions: number[] = [];
    const cadList : TCadListItem[] = [];

    cadNodes.forEach(
        ({ node }) => {

            const { cad_number, version  } = node;

            if(existedVersions.includes(version)){
                const idx = cadList.findIndex(( item ) => item.cad.version === version);

                cadList[idx].versions.push({ ...node });
                cadList[idx].cad.count++;

                return null;
            } else {

                existedVersions.push(version);

                cadList.push({
                    cad : {
                        version,
                        cadNumber : cad_number,
                        count : 1,
                    },
                    versions : [ {
                        ...node
                    } ],
                });
            }
        }
    );

    return cadList;
};

const cadStatusClassHelper = (name ?: string) => {
    switch (name){
        case 'cad_status_in_work'            : return 'in-progress';
        case 'cad_status_cancelled'          : return 'cancelled';
        case 'cad_status_blocked'            : return 'blocked';
        case 'cad_status_ready'              : return 'ready';
        case 'cad_status_approved_by_client' : return 'approved';
        default                              : return 'new';
    }
};

const standCadHelpers = {
    listMap : cadListMap,
    statusClass : cadStatusClassHelper
};

export default standCadHelpers;