import FromVariablesSelect from "./FromVariables-select";
import SearchField from "./Search-field";


const LayoutFields = {
    FromVariablesSelect : FromVariablesSelect,
    Search : SearchField
};

export default LayoutFields;
