import { TGraphicsOrder } from "graphql/type/graphics-gql-types";

import type { TNode } from "graphql/type/common-gql-types";

const graphicsOrdersMock : TNode<TGraphicsOrder>[] = [
    {
        node : {
            id : "1",
            number : 1,
            stand_id : 1,
            graphic_supplier_id : 1,
            status : 'New',
            mp_status : '',
            quantity : 1,
            product_type : '',
            material : 'Banner im Framelessrahmen',
            color : 'red3435',
            visible_width : "950",
            visible_height : "1000",
            actual_width : "950",
            actual_height : "1000",
            montage : 'am Stand /Festpreis',
            montage_comment : '502 - links: Seite 2',
            component : '',
            price_offer : 0,
            area_order : '7.32',
            area_paid : '7.32',
            supplier_order : '5076771',
            price : 14.00,
            price_montage : 5.50,
            total : 157.29,
            relStand : {
                id : "1",
                ee_id : 1,
                company_name : 'Siemens',
                hall_title : 'CCL 1',
                block : 'B13-T1-A34',
                stand_number : 'A34',
                proauf : 'PROAUF012345678',
            },
            updated_at : '0',
            created_at : '0',
        }
    },
    {
        node : {
            id : "3",
            number : 1,
            stand_id : 2,
            graphic_supplier_id : 1,
            status : 'New',
            mp_status : '',
            quantity : 1,
            product_type : '',
            material : 'Banner im Framelessrahmen',
            color : 'red3435',
            visible_width : "950",
            visible_height : "1000",
            actual_width : "950",
            actual_height : "1000",
            montage : 'am Stand /Festpreis',
            montage_comment : '502 - links: Seite 2',
            component : '',
            price_offer : 0,
            area_order : '7.32',
            area_paid : '7.32',
            supplier_order : '5076771',
            price : 14.00,
            price_montage : 5.50,
            total : 157.29,
            relStand : {
                id : "2",
                ee_id : 1,
                company_name : 'Rebooking Lounge',
                hall_title : 'CCL 1',
                block : 'B13-T1-A35',
                stand_number : 'A35',
                proauf : 'PROAUF012345678',
            },
            updated_at : '0',
            created_at : '0',
        }
    },
    {
        node : {
            id : "4",
            number : 1,
            stand_id : 2,
            graphic_supplier_id : 1,
            status : 'New',
            mp_status : '',
            quantity : 1,
            product_type : '',
            material : 'Banner im Framelessrahmen',
            color : 'red3435',
            visible_width : "950",
            visible_height : "1000",
            actual_width : "950",
            actual_height : "1000",
            montage : 'am Stand /Festpreis',
            montage_comment : '502 - links: Seite 2',
            component : '',
            price_offer : 0,
            area_order : '7.32',
            area_paid : '7.32',
            supplier_order : '5076771',
            price : 14.00,
            price_montage : 5.50,
            total : 157.29,
            relStand : {
                id : "3",
                ee_id : 1,
                company_name : 'Wefapress Beck + Co. GmbH',
                hall_title : 'KSH-100',
                block : '03-BL-A23',
                stand_number : 'A23',
                proauf : 'PROAUF012345678',
            },
            updated_at : '0',
            created_at : '0',
        }
    }
];

export default graphicsOrdersMock;