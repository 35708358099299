import React, { JSX } from "react";

import { Select } from "antd";

import { Icons } from "components/layout";
import { useGraphicsSuppliers } from "components/use-hook";

import type { TID } from "graphql/type/common-gql-types";


const { Option } = Select;

interface IGraphicsProductTypeSelectProps {
    organizerId    : TID
    showEmpty     ?: boolean
}

const GraphicsSupplierSelect : React.FC<IGraphicsProductTypeSelectProps> = (
    {
        organizerId,
        showEmpty = true,
        ...props
    }) : JSX.Element => {

    const { graphicsSuppliers } = useGraphicsSuppliers( { organizerId }) || {};

    return(
        <Select
            { ...props }
            defaultValue={  '' }
            suffixIcon    = { <Icons.Arrow /> }
        >
            { showEmpty &&
                <Option key={ 0 } value={ "" }>Not selected</Option>
            }
            { graphicsSuppliers?.map(
                ({ id, title  }) => {
                    return(
                        <Option key={ id } value={ id } >{ title }</Option>
                    );
                })
            }
        </Select>
    );
};

export default GraphicsSupplierSelect;