import { gql } from "@apollo/client";


export const TASK_CREATE = gql`
    mutation TaskCreate($standId: ID!) {
        taskCreate(stand_id: $standId){
            label
            message
            task {
                id
                title
                stand_id
                ee_id
                status
                importance
                cad_number
                type
                creator_id
                assigned_to_id
                total_comment
                description
                relStand {
                    id
                    ee_id
                    company_name
                    relCad {
                        id
                        cad_number
                        status
                    }
                }
                relCreator {
                    id
                    name
                    surname
                }
                relAssignedTo {
                    id
                    name
                    surname
                }
                relCad {
                    id
                    ee_id
                    stand_id
                    task_id
                    cad_number
                    status
                    version
                    correction
                    file_path
                    created_at
                    updated_at
                }
                relAttachments {
                    id
                    server_name
                    original_name
                    extension
                    title
                    description
                    path
                }
                created_at
                updated_at
            }
        }
    }
`;


export const TASK_UPDATE = gql`
    mutation TaskUpdate($input: TaskFieldsInput!) {
        taskUpdate(input: $input){
            label
            message
            task {
                id
                title
                stand_id
                ee_id
                status
                importance
                cad_number
                type
                creator_id
                assigned_to_id
                total_comment
                description
                relStand {
                    id
                    ee_id
                    company_name
                }
                relCreator {
                    id
                    name
                    surname
                }
                relAssignedTo {
                    id
                    name
                    surname
                }
                relCad {
                    id
                    ee_id
                    stand_id
                    task_id
                    cad_number
                    status
                    version
                    correction
                    file_path
                    created_at
                    updated_at
                }
                relAttachments {
                    id
                    server_name
                    original_name
                    extension
                    title
                    description
                    path
                }
                created_at
                updated_at
            }
        }
    }
`;


export const TASK_UPDATE_STATUS = gql`
    mutation TaskUpdateStatus($input: TaskFieldsInput!) {
        taskUpdate(input: $input){
            label
            message
            task {
                id
                status
                created_at
                updated_at
            }
        }
    }
`;

export const TASK_DELETE = gql`
    mutation taskDelete($id: ID!) {
        taskDelete(id: $id){
            label
            message
        }
    }
`;

export const TASK_ATTACHMENT_UPLOAD = gql`
    mutation attachmentCreate($input: AttachmentUploadInput!) {
        attachmentCreate(input: $input){
            label
            message
            model {
                ... on Task{
                    id
                    relAttachments {
                        id
                        server_name
                        original_name
                        extension
                        title
                        description
                        path
                    }
                }
            }
        }
    }
`;

export const TASK_COMMENT_CREATE = gql`
    mutation TaskCommentCreate($input: TaskCommentFieldsInput!) {
        taskCommentCreate(input: $input){
            label
            message
            taskComment {
                id
                task_id
                author_id
                description
                relAuthor {
                    id
                    name
                    surname
                }
                relAttachments {
                    id
                    server_name
                    original_name
                    extension
                    title
                    description
                    path
                }
                created_at
                updated_at
            }
        }
    }
`;

export const TASK_COMMENT_UPDATE = gql`
    mutation TaskCommentUpdate($input: TaskCommentFieldsInput!) {
        taskCommentUpdate(input: $input){
            label
            message
            taskComment {
                id
                task_id
                author_id
                description
                relAuthor {
                    id
                    name
                    surname
                }
                relAttachments {
                    id
                    server_name
                    original_name
                    extension
                    title
                    description
                    path
                }
                created_at
                updated_at
            }
        }
    }
`;

export const TASK_COMMENT_DELETE = gql`
    mutation taskCommentDelete($id: ID!) {
        taskCommentDelete(id: $id){
            label
            message
        }
    }
`;
