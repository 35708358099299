import React, { JSX, useState } from "react";

import { Button, FormInstance } from "antd";

import StandForms from "../forms";
import { Icons } from "components/layout";
import { ModalStandard } from "components/service";

import type { TID } from "graphql/type/common-gql-types";
import type { TStandCad } from "graphql/type/stand-gql-types";


interface IStandCadChangeCurrentProps {
    standId     : TID
    currentCad  : TStandCad
    btnClass   ?: string
}

const StandCadChangeCurrentModal: React.FC<IStandCadChangeCurrentProps> = (
    {
        btnClass = "",
        ...props
    }) : JSX.Element => {

    const [ openModal, setOpenModal ] = useState( false ),
          [ form, setForm ] = useState<FormInstance | undefined>(undefined);

    return(
        <>
            <Button
                type      = "text"
                className = { btnClass }
                onClick   = { () => setOpenModal(true) }
            >
                <Icons.Substitute /> Change
            </Button>
            <ModalStandard
                title      = { <h2>Change current CAD</h2> }
                isOpen     = { openModal }
                extraClass = { "stand-cad-change-current-modal" }
                close      = { () => {
                    setOpenModal(false);
                }}
                okText     = { 'Change' }
                confirm    = {
                    () => {

                        form && form.submit();
                        //setOpenModal(false);
                    }
                }
            >
                <StandForms.CadStatus
                    { ...props }
                    setForm     = { setForm }
                    onUpdate    = { () =>  setOpenModal(false) }
                    cadEditable = { true }
                />     
            </ModalStandard>
        </>
    );
};

export default StandCadChangeCurrentModal;