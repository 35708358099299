import { useQuery } from "@apollo/client";

import { GET_GRAPHICS_SUPPLIERS } from "graphql/query/graphics-q.gql";


import { IUseGraphQLHook, IUseGraphQLHookProps } from "common/types";
import type { TID } from "graphql/type/common-gql-types";
import type { TGraphicsSupplier } from "graphql/type/graphics-gql-types";


interface IUseGraphicsSuppliersProps extends IUseGraphQLHookProps{
    organizerId  ?: TID
}

interface IUseGraphicsSuppliersPayload extends IUseGraphQLHook{
    graphicsSuppliers :  TGraphicsSupplier[] | undefined
}

const useGraphicsSuppliers = ({
        fetchPolicy = "cache-only",
        withStatus  = false,
        organizerId,
    } : IUseGraphicsSuppliersProps ) : IUseGraphicsSuppliersPayload  => {

    const {
        data : { graphicsSuppliersCursor  } = { },
        loading,
        error
    } = useQuery( GET_GRAPHICS_SUPPLIERS, {
        variables : {
            where : organizerId ? {
                column : "ORGANIZER_ID",
                operator : "EQ",
                value : organizerId,
            } : undefined,
            orderBy : [ { column : "ID", order : "ASC" } ]
        },
        skip : !organizerId,
        fetchPolicy
    });

    const {
        edges = []
    } = graphicsSuppliersCursor || {};

    const graphicsSuppliers = edges.map(({ node }) => {
        return  { ...node };
    });

    return (withStatus) ?
        {
            graphicsSuppliers,
            loading,
            error
        } :
        { graphicsSuppliers };


};

export default useGraphicsSuppliers;