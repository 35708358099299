import React, { JSX, useState } from "react";

import { Button, FormInstance } from "antd";

import GraphicsForms from "../forms";
import { Icons } from "components/layout";
import { ModalStandard } from "components/service";

import type { TID } from "graphql/type/common-gql-types";


interface IStandCadChangeCurrentProps {
    standId     : TID
    organizerId : TID
    btnClass   ?: string
}

const GraphicsOrderCreateModal: React.FC<IStandCadChangeCurrentProps> = (
    {
        btnClass = "",
        ...props
    }) : JSX.Element => {

    const [ openModal, setOpenModal ] = useState( false ),
        [ form, setForm ] = useState<FormInstance | undefined>(undefined);

    return(
        <>
            <Button
                type      = "primary"
                className = { btnClass }
                onClick   = { () => setOpenModal(true) }
            >
                <Icons.Plus /> Add order
            </Button>
            <ModalStandard
                title      = { <h2>Create Graphics order</h2> }
                isOpen     = { openModal }
                extraClass = { "graphics-order-create-modal" }
                close      = { () => {
                    setOpenModal(false);
                }}
                okText     = { 'Create' }
                confirm    = {
                    () => {

                        form && form.submit();
                        //setOpenModal(false);
                    }
                }
            >
                <GraphicsForms.OrderCreate
                    {...props }
                    setForm     = { setForm }
                    onUpdate    = { () =>  setOpenModal(false) }
                />
            </ModalStandard>
        </>
    );
};

export default GraphicsOrderCreateModal;