import React from "react";

import {  TStandItem } from "graphql/type/stand-gql-types";
import { generatePath, Link } from "react-router-dom";

import StandMenus from "../menus";
import { Icons } from "components/layout";
import { ROUTES } from "components/routes";
import { TagStandard } from "components/service";
import Localize from "components/service/Localize";
import Task from "components/task";

import type { TLocales } from "common/types";
import type { TNode } from "graphql/type/common-gql-types";
import type { TStandTypeRelation } from "graphql/type/stand-type-gql-types";
import type { FixedType } from "rc-table/lib/interface";


const columns  = [
    {
        title :
            <>
                <div className="table-section-label">
                    General info
                </div>
                COMPANY
            </>
        ,
        dataIndex : "title",
        columnIndex : "TITLE",
        width : 240,
        fixed : 'left' as FixedType,
        className : "general-info"
        //sorter: true,
    },
    {
        title : "HALL",
        dataIndex : "hall",
        columnIndex : "HALL_NUMBER",
        className : "general-info",
        width : 120,
    },
    {
        title : "BLOCK",
        dataIndex : "block",
        columnIndex : "BLOCK_NUMBER",
        className : "general-info",
        width : 120,
    },
    {
        title : "STAND №",
        dataIndex : "standNumber",
        columnIndex : "STAND_NUMBER",
        className : "general-info",
        width : 80,
    },
    {
        title : "PROAUF",
        dataIndex : "proauf",
        columnIndex : "PROAUF_NUMBER",
        className : "general-info",
        width : 90,
    },
    {
        title : "STAND TYPE",
        dataIndex : "standType",
        columnIndex : "STAND_TYPE",
        className : "general-info",
        width : 260,
    },
    {
        title : "ORIENTATION",
        dataIndex : "standOrientation",
        columnIndex : "ORIENTATION",
        className : "general-info",
        width : 130,
    },
    {
        title : "SIZE m² (WxH)",
        dataIndex : "size",
        columnIndex : "SQUARE_METER",
        className : "general-info",
        width : 100,
    },
    {
        title : "FLOOR COVERING",
        dataIndex : "floorCovering",
        columnIndex : "FLOOR_COVERING",
        className : "general-info",
        width : 180,
    },
    {
        title : "PROJECT MANAGER",
        dataIndex : "projectManager",
        columnIndex : "PROJECT_MANAGER_ID",
        className : "general-info",
        width : 140,
    },
    {
        title : "AUTOCAD ENGINEER",
        dataIndex : "autocadEngineer",
        columnIndex : "AUTOCAD_ENGINEER",
        className : "general-info",
        width : 140,
    },
    {
        title : "COMMENTS",
        dataIndex : "commentsCount",
        columnIndex : "COMMENTS_COUNT",
        className : "general-info",
        width : 90,
    },
    {
        title :
            <>
                <div className="table-section-label">
                    EQUIPMENT
                </div>
                ADDITIONAL ORDER
            </>,
        dataIndex : "additionalOrder",
        columnIndex : "ADDITIONAL_ORDER",
        className : "equipment",
        width : 280,
    },
    {
        title : "COMMENT",
        dataIndex : "equipmentComment",
        columnIndex : "EQUIPMENT_COMMENT",
        className : "equipment",
        width : 260,
    },
    {
        title :
            <>
                <div className="table-section-label">
                    GRAPHICS
                </div>
                ORDERS NUMBER
            </>,
        dataIndex : "ordersNumber",
        columnIndex : "ORDERS_NUMBER",
        className : "graphics",
        width : 150,
    },
    {
        title :
            <>
                <div className="table-section-label">
                    CAD INFO
                </div>
                CAD NUMBER
            </>,
        dataIndex : "cadNumber",
        columnIndex : "CAD_NUMBER",
        className : "cad-info",
        width : 100,
    },
    {
        title : "STATUS",
        dataIndex : "cadStatus",
        columnIndex : "CAD_STATUS",
        className : "cad-info",
        width : 180,
    },
    {
        title : "CHANGES",
        dataIndex : "cadChanges",
        columnIndex : "CAD_CHANGES",
        className : "cad-info",
        width : 80,
    },
    {
        title : "NEWEST VERSION",
        dataIndex : "cadVersion",
        columnIndex : "CAD_VERSION",
        className : "cad-info",
        width : 150,
    },
    {
        title :
            <>
                <div className="table-section-label">
                    Current task
                </div>
                IMPORTANCE
            </>,
        dataIndex : "taskImportance",
        columnIndex : "TASK_IMPORTANCE",
        className : "task-info",
        width : 100,
    },
    {
        title : "ASSIGNED TO",
        dataIndex : "taskAssignedTo",
        columnIndex : "TASK_ASSIGNED_TO",
        className : "task-info",
        width : 140
    },
    {
        title : "STATUS",
        dataIndex : "taskStatus",
        columnIndex : "TASK_STATUS",
        className : "task-info",
        width : 140
    },
    {
        title : "TASK TYPE",
        dataIndex : "taskType",
        columnIndex : "TASK_TYPE",
        className : "task-info",
        width : 140
    },
    {
        title : "TASK DETAILS",
        dataIndex : "task",
        columnIndex : "TASK",
        className : "task-info",
        width : 300
    },
    {
        dataIndex : "action",
        width : 60,
        fixed : 'right' as FixedType,
        className : "actions"

    }
];


const dataMap = ( stands : TNode<TStandItem<TStandTypeRelation>>[], lang : TLocales, ) => {

    return stands.map( ({ node  }) => {

        const {
            id,
            ee_id,
            company_name,
            relStandType,
            relStandCommentEquipment,
            relAutocadEngineer,
            relProjectManager,
            relStandEquipmentOrders,
            size_width,
            size_height,
            square_meter,
            relCad,
            relTasks : task,
        } = node;

        const [ standType ] = relStandType,
              orientation = standType.orientations.find(
                  ( { orientation_key } ) => orientation_key === node.orientation_key
              );

        const additionalOrders = relStandEquipmentOrders?.filter(
            (order) => order.set_type !== 'default'
        );

        return{
            key : id,
            title :
                <Link className="item-link" to={
                    generatePath(
                        ROUTES.STAND_ITEM_FULL,
                        {
                            eeId : Number(ee_id),
                            standId : Number(id)
                        }
                    )
                }>
                    { company_name }
                </Link>,
            hall : node.hall_title || <>—</>,
            block : node.block || <>—</>,
            standNumber : node.stand_number || <>—</>,
            proauf : <p className="proauf">{ node.proauf || <>—</>}</p>,
            standType : standType.title,
            standOrientation : orientation ?
                <div className="orientation">
                    <span className="img-wrap">
                        <img src={ orientation!.img_path } alt={`${ orientation!.orientation_key } image`}/>
                    </span>
                    <Localize wrap className="title">
                        {`VARIABLE_ITEM.${ orientation!.orientation_key }`}
                    </Localize>
                </div> : <>—</>,
            size : `${square_meter} (${size_width}x${size_height})`,
            floorCovering : node.flooring_title || <>—</>,
            projectManager : relProjectManager ? `${relProjectManager.name} ${relProjectManager.surname}` : <>—</>,
            autocadEngineer : relAutocadEngineer  ? `${relAutocadEngineer.name} ${relAutocadEngineer.surname}` : <>—</>,
            commentsCount :
                <>
                    <Icons.Comment/>
                    {node.total_comment || 0}
                </>,
            additionalOrder :
                additionalOrders && !!additionalOrders.length ?
                    <div className="additional-orders">
                        {additionalOrders.slice(0, 2).map((order) => {

                            const equipment = order.relEquipment.find(
                                (item) => item.lang === lang
                            );

                            if(!equipment) return null;

                            return(
                                <p key={order.id}>
                                    <span className="amount">{order.qty}x</span> {equipment.title}
                                </p>
                            );
                        })}
                        { additionalOrders.length - 2 > 0 &&
                            <p className="more" key="more">
                                <span>+{ additionalOrders.length - 2 } more</span>
                                <Link className="table-link" to={
                                    generatePath(
                                        ROUTES.STAND_ITEM_EQUIPMENT_FULL,
                                        {
                                            eeId : Number(ee_id),
                                            standId : Number(id)
                                        }
                                    )
                                }>
                                    { `view >` }
                                </Link>
                            </p>
                        }
                    </div> : <>—</>,
            equipmentComment :
                relStandCommentEquipment && relStandCommentEquipment.comment !== null ? relStandCommentEquipment.comment : <>—</>
            ,
            cadNumber : relCad ? relCad.cad_number :  <>—</>,
            cadStatus : relCad ?
                <TagStandard className={Task.Helpers.status(relCad.status)}>
                    <Localize>{`VARIABLE_ITEM.${ relCad.status }`}</Localize>
                </TagStandard> :  <>—</>,
            cadChanges : relCad ? relCad.correction :  <>—</>,
            cadVersion : relCad ?
                <div className="cad-version>">
                    Version { relCad.version }.{ relCad.correction } <br />
                    { relCad.updated_at }
                </div> :
                <>—</>,
            taskImportance : task ?
                <Task.Helpers.Importance name={ task.importance } onlyIcon={false} /> : <>—</>,
            taskAssignedTo : task && task.relAssignedTo  ?
                <>{task.relAssignedTo.name} {task.relAssignedTo.surname}</> : <>—</>,
            taskStatus : task ?
                <TagStandard className={Task.Helpers.status(task.status)}>
                    <Localize>{`VARIABLE_ITEM.${ task.status }`}</Localize>
                </TagStandard>
                : <>—</>,
            taskType : task ?
                <Localize wrap className="type text" tag="p">
                    {`VARIABLE_ITEM.${ task.type }`}
                </Localize>
                : <>—</>,
            task : task ?
                <>
                    <span>{ task.title }</span>
                    <Link className="table-link" to={
                        generatePath(
                            ROUTES.STAND_ITEM_TASK_FULL,
                            {
                                taskId : Number(task.id),
                                eeId : Number(ee_id),
                                standId : Number(id)
                            }
                        )
                    }>
                        { `view >` }
                    </Link>
                </>
                : <>—</>,
            action :
                <StandMenus.TableItem
                    eeId        = { ee_id }
                    standId     = { id }
                    companyName = { company_name }
                />
        };

    });
};

const StandTableHelper = {
    columns,
    totalColumnWidth : () : number => {
        return columns.reduce( (total, item) => total + item.width + 3, 0) + 20;
    },
    dataMap,
};

export default StandTableHelper;