import React, { JSX, useEffect } from "react";

import { Col, Row } from "antd";
import { generatePath, useNavigate, useOutletContext, useParams } from "react-router-dom";

import { ROUTES } from "components/routes";
import Task from "components/task";

import type { TStandItem } from "graphql/type/stand-gql-types";
import type { TStandType } from "graphql/type/stand-type-gql-types";


const StandTasksSubPage : React.FC = () : JSX.Element => {

    const { stand , ee } = useOutletContext<{ stand: TStandItem<TStandType>,  ee: any }>();

    const navigate = useNavigate(),
          pParams = useParams();

    useEffect(() => {
        if(!pParams.taskId && stand.relTasks && stand.relTasks.id){
            navigate(
                generatePath(
                    ROUTES.STAND_ITEM_TASK_FULL,
                    {
                        taskId : Number(stand.relTasks.id),
                        eeId : Number(ee.id),
                        standId : Number(stand.id)
                    }
                ),
                {
                    replace : true
                }
            );
        }
    }, [ pParams ]);

    return(
        <Row className="page-content stand-tasks">
            <Col
                {...{ xxl : 7, xl : 8, lg : 6, span : 24 }}
                className = { 'left-col' }
            >
                <Task.Lists.All
                    withHeader = { true }
                    standId    = { stand.id }
                    eeId       = { ee.id }
                    teamId     = { ee.team_id }
                />
            </Col>
            <Col
                {...{ xxl : 12, xl : 16, lg : 18, span : 24 }}
                className={'right-col'}
            >
                { pParams.taskId  &&
                    <Task.Blocks.View
                        taskId     = { pParams.taskId }
                        standId    = { stand.id }
                        eeId       = { ee.id }
                        teamId     = { ee.team_id }
                    />
                }
            </Col>
        </Row>
    );
};

export default StandTasksSubPage;