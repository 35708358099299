import React from "react";

import { generatePath, Link } from "react-router-dom";

import { STAND_DELETE } from "graphql/mutation/stand-m.gql";

import { Icons } from "../../layout";
import { Menus } from "components/layout";
import { ROUTES } from "components/routes";
import { DeleteButton, LinkWithIcon, TagStandard } from "components/service";

import type { TNode } from "graphql/type/common-gql-types";
import type { TGraphicsOrder } from "graphql/type/graphics-gql-types";
import type { FixedType } from "rc-table/lib/interface";


const columns  = [
    {
        title :
            <>
                CLIENT NAME <br />
                HALL / STAND / BLOCK
            </>
        ,
        dataIndex : "companyName",
        columnIndex : "COMPANY_NAME",
        width : 260,
        fixed : 'left' as FixedType,
        //sorter: true,
    },
    {
        title :
            <>
                READINESS <br />
                STATUS
            </>
        ,
        dataIndex : "status",
        columnIndex : "STATUS",
        width : 150,
    },
    {
        title : <>PRODUCTS QUANTITY</>,
        dataIndex : "quantity",
        columnIndex : "Quantity",
        width : 120,
    },
    {
        title : <>MP STATUS</>,
            dataIndex : "mpStatus",
        columnIndex : "MP_STATUS",
        width : 120,
    },
    {
        title : <>PRODUCT TYPE</>,
        dataIndex : "productType",
        columnIndex : "PRODUCT_TYPE",
        width : 150,
    },
    {
        title : <>MATERIAL</>,
        dataIndex : "material",
        columnIndex : "MATERIAL",
        width : 180,
    },
    {
        title : <>PRINTING DATA</>,
        dataIndex : "printingData",
        columnIndex : "PRINTING_DATA",
        width : 200,
    },
    {
        title :
            <>
                COLOR <br />
                (ORACAL)
            </>
        ,
        dataIndex : "color",
        columnIndex : "COLOR",
        width : 150,
    },
    {
        title :
            <>
                VISIBLE W <br />
                (MM)
            </>
        ,
        dataIndex : "visibleWidth",
        columnIndex : "VISIBLE_WIDTH",
        width : 80,
    },
    {
        title :
            <>
                VISIBLE H <br />
                (MM)
            </>
        ,
        dataIndex : "visibleHeight",
        columnIndex : "VISIBLE_HEIGHT",
        width : 80,
    },
    {
        title :
            <>
                ACTUAL W <br />
                (MM)
            </>
        ,
        dataIndex : "actualWidth",
        columnIndex : "ACTUAL_WIDTH",
        width : 80,
    },
    {
        title :
            <>
                ACTUAL H <br />
                (MM)
            </>
        ,
        dataIndex : "actualHeight",
        columnIndex : "ACTUAL_HEIGHT",
        width : 80,
    },
    {
        title : <>MONTAGE TYPE</>,
        dataIndex : "montageType",
        columnIndex : "MONTAGE_TYPE",
        width : 150,
    },
    {
        title : <>MONTAGE COMMENT</>,
        dataIndex : "montageComment",
        columnIndex : "MONTAGE_COMMENT",
        width : 250,
    },
    {
        title : <>EP OFFER</>,
        dataIndex : "priceOffer",
        columnIndex : "PRICE_OFFER",
        width : 120,
    },
    {
        title : <>MP INTERNAL ORDER №</>,
        dataIndex : "mpInternalOrder",
        columnIndex : "MP_INTERNAL_ORDER",
        width : 120,
    },
    {
        title : <>NUMBER OF LETTERS</>,
        dataIndex : "numberOfLetters",
        columnIndex : "NUMBER_OF_LETTERS",
        width : 100,
    },
    {
        title : <>EFFECTIVE AREA [M²]</>,
        dataIndex : "effectiveArea",
        columnIndex : "EFFECTIVE AREA",
        width : 100,
    },
    {
        title : <>CALCULATED AREA [M²]</>,
        dataIndex : "calculatedArea",
        columnIndex : "CALCULATED AREA",
        width : 100,
    },
    {
        title : <>GRAPHIC COMPANY ORDER №</>,
        dataIndex : "supplierOrder",
        columnIndex : "SUPPLIER_ORDER",
        width : 150,
    },
    {
        title : <>PRODUCTION<br />PRICE (€)</>,
        dataIndex : "priceProduction",
        columnIndex : "PRODUCTION_PRICE",
        width : 100,
    },
    {
        title :
            <>MONTAGE<br />PRICE (€)</>,
        dataIndex : "priceMontage",
        columnIndex : "MONTAGE_PRICE",
        width : 100,
    },
    {
        title : <>TOTAL (€)</>,
        dataIndex : "total",
        columnIndex : "Total",
        width : 100,
    },
    {
        dataIndex : "action",
        width : 60,
        fixed : 'right' as FixedType,
        className : "actions"

    }
];


const dataMap = ( graphicsOrders : TNode<TGraphicsOrder>[], ) => {

    return graphicsOrders.map( ({ node  }) => {

        const {
            id,
            material,
            color,
            status,
            visible_width,
            visible_height,
            actual_width,
            actual_height,
            quantity,
            area_order,
            area_paid,
            montage_comment,
            supplier_order,
            price,
            price_montage,
            total,
            relStand,
        } = node;


        return{
            key : id,
            companyName :
                <>
                    <Link className="item-link" to={
                        generatePath(
                            ROUTES.STAND_ITEM_FULL,
                            {
                                eeId : Number(relStand.ee_id),
                                standId : Number(relStand.id)
                            }
                        )
                    }>
                        { relStand.company_name }
                    </Link>
                    <p>
                        { relStand.hall_title } / { relStand.stand_number } / { relStand.block }
                    </p>
                </>,
            status :
                <TagStandard className={'new'} >
                    { status }
                </TagStandard>,
            quantity : quantity,
            mpStatus : <>—</>,
            productType : <>—</>,
            material : material ? material : <>—</>,
            printingData : <>—</>,
            color : color ? color : <>—</>,
            visibleWidth : visible_width ? visible_width : <>—</>,
            visibleHeight : visible_height ? visible_height : <>—</>,
            actualWidth : actual_width ? actual_width : <>—</>,
            actualHeight : actual_height ? actual_height : <>—</>,
            montageType : <>—</>,
            montageComment :
                montage_comment ?
                    <>
                        <Icons.Comment /> { montage_comment }
                    </> : <>—</>,
            priceOffer : <>—</>,
            mpInternalOrder : <>—</>,
            numberOfLetters : <>—</>,
            effectiveArea : area_order ? area_order : <>—</>,
            calculatedArea : area_paid ?  area_paid : <>—</>,
            supplierOrder : supplier_order ? supplier_order : <>—</>,
            priceProduction : price ? price : <>—</>,
            priceMontage : price_montage ? price_montage : <>—</>,
            total : total ? total : <>—</>,
            action :
                <Menus.Popover>
                    <LinkWithIcon
                        route       = { "STAND_ITEM_GRAPHIC_FULL" }
                        routeParams = {{
                            eeId : Number(relStand.ee_id),
                            standId : Number(relStand.id)
                        }}
                        icon        = { "Edit" }
                        className   = { "link-text" }
                    >
                        Edit
                    </LinkWithIcon>
                    <DeleteButton
                        className   = { "stand-delete" }
                        buttonType  = { "text" }
                        id          = { Number(0) }
                        query       = { STAND_DELETE }
                        reFetch     = {[ "GetStands" ]}
                        confirmText = { `Do you want to delete this graphics?` }
                    >
                        Delete
                    </DeleteButton>
                </Menus.Popover>
        };

    });
};


const graphicsTableHelper = {
    columns,
    totalColumnWidth : () : number => {
        return columns.reduce( (total, item) => total + item.width + 3, 0) + 20;
    },
    dataMap,
};

export default graphicsTableHelper;