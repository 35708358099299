import { gql } from "@apollo/client";


export const GRAPHICS_ORDER_CREATE = gql`
    mutation GraphicsOrderCreate($input: GraphicsOrderFieldsInput!) {
        graphicsOrderCreate(input: $input){
            label
            message
            graphicsOrder {
                id
                number
                stand_id
                graphic_supplier_id
                status
                mp_status
                quantity
                product_type
                material
                color
                visible_width
                visible_height
                actual_width
                actual_height
                montage
                montage_comment
                component
                price_offer
                area_order
                area_paid
                supplier_order
                price
                price_montage
                total
                created_at
                updated_at
            }
        }
    }
`;

export const GRAPHICS_ORDER_UPDATE = gql`
    mutation GraphicsOrderUpdate($input: GraphicsOrderFieldsInput!) {
        graphicsOrderUpdate(input: $input){
            label
            message
            graphicsOrder {
                id
                number
                stand_id
                graphic_supplier_id
                status
                mp_status
                quantity
                product_type
                material
                color
                visible_width
                visible_height
                actual_width
                actual_height
                montage
                montage_comment
                component
                price_offer
                area_order
                area_paid
                supplier_order
                price
                price_montage
                total
                created_at
                updated_at
            }
        }
    }
`;

export const GRAPHICS_ORDER_DELETE = gql`
    mutation graphicsOrderDelete($id: ID!) {
        graphicsOrderDelete(id: $id){
            label
            message
        }
    }
`;
