import React, { JSX } from "react";

import { useMutation } from "@apollo/client";
import { Button, Form, FormInstance, /*FormInstance,*/ Input, InputNumber } from "antd";

import { GRAPHICS_ORDER_DELETE, GRAPHICS_ORDER_UPDATE } from "graphql/mutation/graphics-m.gql";

import GraphicsFields from "../fields";
import GraphicsHelpers from "../helpers";
import useGraphicsSupplierFragment from "../hooks/useGraphicsSupplierFragment";
import { Fields, Icons } from "components/layout";
import { DeleteButton } from "components/service";
import { useNotification } from "components/use-hook";

import type { TID } from "graphql/type/common-gql-types";
import type { TGraphicsOrder } from "graphql/type/graphics-gql-types";


const { TextArea } = Input;

interface IGraphicsOrderFormProps {
    standId          : TID
    organizerId      : TID
    graphicsOrder    : TGraphicsOrder
}

const GraphicsOrderEditForm : React.FC<IGraphicsOrderFormProps> = (
    {
        standId,
        organizerId,
        graphicsOrder,
    }) : JSX.Element => {

    const [ form ] = Form.useForm();
    const { nError, nSuccess } = useNotification();

    const [ graphicsOrderEdit, { loading } ] = useMutation(
        GRAPHICS_ORDER_UPDATE,
        {
            update(_, { data }) {

                const { 'graphicsOrderUpdate' : {
                    label,
                    message,
                }  } = data;

                nSuccess(label, message);
            },
            onError(error) {
                console.error(error);
            }
        }
    );


    const supplierId : TID = Form.useWatch<string, FormInstance>('graphic_supplier_id', form) || graphicsOrder.graphic_supplier_id;

    const graphicsSupplier = useGraphicsSupplierFragment({ supplierId });


    const canSave = true;

    return(
        <div className = "graphics-order-edit-form">
            <Form
                className     = { "inner-forms" }
                form          = { form }
                layout        = "vertical"
                initialValues = {
                    graphicsSupplier && graphicsOrder ?
                        GraphicsHelpers.editForm.initialData(graphicsOrder/*, graphicsSupplier*/) : undefined
                }
                onValuesChange={ (changedValues, values) =>
                    GraphicsHelpers.editForm.changedData(
                        changedValues,
                        values,
                        graphicsSupplier?.relRule || [],
                        form)
                }
                onFinish={(values) => {
                    if (!loading && canSave) {
                        graphicsOrderEdit({
                            variables : {
                                input : {
                                    ...values,
                                    id : graphicsOrder.id,
                                    stand_id : standId,
                                    total : values.total
                                }
                            }
                        }).catch(nError);
                    }
                }}
            >
                <div className="grid-two-col">
                    <Form.Item
                        label = {"Rule set"}
                        name  = "graphic_supplier_id"
                        rules = {[ {
                            required : true,
                            message : "required"
                        } ]}
                    >
                        <GraphicsFields.SupplierSelect
                            organizerId = { organizerId }
                            showEmpty   = { false }
                        />
                    </Form.Item>
                </div>
                <div className="grid-three-col">
                    <Form.Item
                        label = {"Readiness Status"}
                        name  = "status"
                        rules = {[ {
                            required : true,
                            message : "required"
                        } ]}
                    >
                        <Fields.FromVariablesSelect
                            variableGroup = {'graphic status'}
                        />
                    </Form.Item>
                    <Form.Item
                        label = {"MP Status"}
                        name  = "mp_status"
                    >
                        <Fields.FromVariablesSelect
                            variableGroup   = {'mp status'}
                        />
                    </Form.Item>
                    <Form.Item
                        label = {"Quantity"}
                        name  = "quantity"
                        rules = {[
                            {
                                type : "number",
                                min : 1
                            },
                            {
                                required : true,
                                message : "required"
                            }
                        ]}
                    >
                        <InputNumber name="graphic-quantity"/>
                    </Form.Item>
                </div>
                <div className="grid-two-col">
                    <Form.Item
                        label = {"Product type"}
                        name  = "product_type"
                        rules = {[ {
                            required : true,
                            message : "required"
                        } ]}
                    >
                        <GraphicsFields.ProductTypeSelect
                            supplierId={ supplierId }
                        />
                    </Form.Item>
                    <Form.Item
                        label = {"Montage"}
                        name  = "montage"
                        rules = {[ {
                            required : true,
                            message : "required"
                        } ]}
                    >
                        <Fields.FromVariablesSelect
                            allowedOptions = { graphicsSupplier?.relVariableList }
                            variableGroup  = {'assembly type'}
                        />
                    </Form.Item>
                </div>
                <div className="grid-two-col">
                    <Form.Item
                        label = {"Material"}
                        name  = "material"
                        rules = {[ {
                            required : true,
                            message : "required"
                        } ]}
                    >
                        <Fields.FromVariablesSelect
                            allowedOptions  = { graphicsSupplier?.relVariableList }
                            variableGroup={'graphic material'}
                        />
                    </Form.Item>
                    <Form.Item
                        label = {"Color (Oracal)"}
                        name  = "color"
                    >
                        <Fields.FromVariablesSelect
                            allowedOptions = { graphicsSupplier?.relVariableList }
                            variableGroup  = {'oracal color'}
                        />
                    </Form.Item>

                </div>
                <div className="grid-four-col">
                    <Form.Item
                        label = {"Visible Width"}
                        name  = "visible_width"
                        rules = {[ {
                            type : "number",
                            min : 0
                        } ]}
                    >
                        <InputNumber name="visible-width"
                                     addonAfter="mm"
                        />
                    </Form.Item>
                    <Form.Item
                        label = {"Visible Height"}
                        name  = "visible_height"
                        rules = {[ {
                            type : "number",
                            min : 0
                        } ]}
                    >
                        <InputNumber name="visible-height"
                                     addonAfter="mm"
                        />
                    </Form.Item>
                    <Form.Item
                        label = {"Actual Width"}
                        name  = "actual_width"
                        rules = {[
                            {
                                type : "number",
                                min : 0
                            },
                            {
                                required : true,
                                message : "required"
                            }
                        ]}
                    >
                        <InputNumber name       = "actual-width"
                                     addonAfter = "mm"
                        />
                    </Form.Item>
                    <Form.Item
                        label = {"Actual Height"}
                        name  = "actual_height"
                        rules = {[
                            {
                                type : "number",
                                min : 0
                            },
                            {
                                required : true,
                                message : "required"
                            }
                        ]}
                    >
                        <InputNumber name       = "actual-height"
                                     addonAfter = "mm"
                        />
                    </Form.Item>
                </div>
                <Form.Item
                    label = {"Montage Comment"}
                    name  = "comment"
                >
                    <TextArea
                        placeholder = "Type comment text"
                        autoSize    = {{ minRows : 1, maxRows : 4 }}
                    />
                </Form.Item>
                <div className="grid-three-col">
                    <Form.Item
                        label = {"Offer price"}
                        name  = "price_offer"
                        rules = {[ {
                            type : "number",
                            min : 0
                        } ]}
                    >
                        <InputNumber name       = "offer-price"
                                     addonAfter = "€"
                        />
                    </Form.Item>
                    <Form.Item
                        label = {"Graphic company order № "}
                        name  = "supplier_order"
                    >
                        <Input name="gc-order-number"/>
                    </Form.Item>
                    <Form.Item
                        label = {"MP internal order №"}
                        name  = "mp_order"
                    >
                        <Input name="mp-order-price"/>
                    </Form.Item>
                </div>
                <div className="grid-three-col">
                    <Form.Item
                        label = {"Effective area"}
                        name  = "area_order"
                        rules = {[ {
                            type : "number",
                            min : 0
                        } ]}
                    >
                        <InputNumber name       = "area-order"
                                     addonAfter = "m²"
                                     disabled
                        />
                    </Form.Item>
                    <Form.Item
                        label = {"Calculated area"}
                        name  = "area_paid"
                        rules = {[ {
                            type : "number",
                            min : 0
                        } ]}
                    >
                        <InputNumber name       = "area-paid"
                                     addonAfter = "m²"
                                     disabled
                        />
                    </Form.Item>
                    <Form.Item
                        label = {"Number of letters"}
                        name  = "letters_amount"
                        rules = {[ {
                            type : "number",
                            min : 0
                        } ]}
                    >
                        <InputNumber name="letters-amount"
                                     disabled
                        />
                    </Form.Item>
                </div>
                <div className="grid-three-col">
                    <Form.Item
                        label = {"Production price"}
                        name  = "price"
                    >
                        <InputNumber name       = "production-price"
                                     addonAfter = "€"
                                     disabled
                        />
                    </Form.Item>
                    <Form.Item
                        label = { "Montage price" }
                        name  = "price_montage"
                    >
                        <InputNumber name       = "montage-price"
                                     addonAfter = "€"
                                     disabled
                        />
                    </Form.Item>
                    <Form.Item
                        label = {"Total"}
                        name  = "total"
                    >
                        <InputNumber name       = "total"
                                     addonAfter = "€"
                                     disabled
                        />
                    </Form.Item>
                </div>
                <div className="form-actions">
                    <div>
                        {graphicsOrder &&
                            <DeleteButton
                                className  = { "stand-delete" }
                                buttonType = { "default" }
                                id         = { Number(graphicsOrder.id) }
                                query      = { GRAPHICS_ORDER_DELETE }
                                reFetch    = { [ 'GetGraphicsOrders' ] }
                            >
                                Delete Order
                            </DeleteButton>
                        }
                    </div>
                    <div>
                        <Button
                            type     = "primary"
                            htmlType = "submit"
                        >
                            <Icons.Edit loading={loading}/>
                            Save changes
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default GraphicsOrderEditForm;