import { FetchPolicy, useQuery } from "@apollo/client";

import { GET_ME } from "graphql/query/user-q.gql";

import { useLogout } from "./index";
import useNotification from "./use-notification";

import { IErrorNotification, IUseGraphQLHook } from "common/types";
import type { TUser } from "graphql/type/user-gql-types";


interface IUseMe extends IUseGraphQLHook{
    me : TUser | null
}

const useMe = (
        fetchPolicy : FetchPolicy = "cache-only",
        withStatus  : boolean = false
    ) : IUseMe  => {

    const { clearStore } = useLogout(),
          { nError } = useNotification();

    const {
        data,
        loading,
        error
    } = useQuery( GET_ME, {
        fetchPolicy,
        onError(e){
            nError(e as unknown as IErrorNotification);
            clearStore({ route : "/" });
            
        }
    });

    const me = data ? data.me : null;


    if(withStatus)
        return {
            me,
            loading,
            error
    };

    return { me };


};

export default useMe;