import StandCadStatusForm from "./Stand-cad-status-form";
import StandChangeTypeSizeForm from "./Stand-change-type-size-form";
import StandEditForm from "./Stand-edit-form";

import "./stand-forms.scss";


const StandForms = {
    Edit : StandEditForm,
    CadStatus : StandCadStatusForm,
    ChangeTypeSize : StandChangeTypeSizeForm
};

export default StandForms;