//import GraphicsButtons from "./buttons";
import GraphicsBlocks from "./blocks";
import GraphicsFields from "./fields";
import GraphicsForms from "./forms";
import GraphicsHelpers from "./helpers";
import GraphicsTables from "./tables";
//import GraphicsLists from "./lists";


const Graphics  = {
    Forms : GraphicsForms,
    Blocks : GraphicsBlocks,
    Fields : GraphicsFields,
    //Buttons : GraphicsButtons,
    Helpers : GraphicsHelpers,
    Tables : GraphicsTables
    //Lists : GraphicsLists
};

export default Graphics;