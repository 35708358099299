import type { TTask } from "graphql/type/task-gql-types";

export const prepareEditInitDataHelper = ( task : TTask ) => {

    const {
        assigned_to_id,
        relAssignedTo,
        ...taskFields
    } = task;

    return {
        ...taskFields,
        type : task.type || "",
        importance : task.importance || 'task_importance_normal',
        assignedTo : assigned_to_id && relAssignedTo ? {
            value : relAssignedTo.id,
            label : `${ relAssignedTo.name } ${ relAssignedTo.surname }`
        } : ""
    };
};


export const prepareEditFormDataHelper = ( values : any ) => {

    const {
        assignedTo,
        ...newValues
    } = values;

    delete newValues['stand_area'];

    return {
        assigned_to_id : assignedTo ? assignedTo.value : undefined,
        ...newValues
    };
};