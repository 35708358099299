import { gql, TypedDocumentNode } from '@apollo/client';

import { IQueryVariables, TID } from "../type/common-gql-types";
import { TTask, TTaskCommentCursorData, TTaskCursorData } from "../type/task-gql-types";


export const GET_TASK: TypedDocumentNode<{ task : TTask }, { id : TID } > = gql`
    query GetTask ( $id : ID! ){
        task(id: $id) {
            id
            title
            stand_id
            status
            importance
            cad_number
            type
            creator_id
            assigned_to_id
            total_comment
            description
            relStand {
                id
                ee_id
                company_name
                relCad {
                    id
                    status
                    cad_number
                }
            }
            relCreator {
                id
                name
                surname
            }
            relAssignedTo {
                id
                name
                surname
            }
            relCad {
                id
                ee_id
                stand_id
                task_id
                cad_number
                status
                version
                correction
                file_path
                created_at
                updated_at
            }
            relAttachments {
                id            
                server_name   
                original_name 
                extension
                title         
                description   
                path          
            }
            created_at
            updated_at
        }
    }
`;

export const GET_TASKS: TypedDocumentNode<{ tasksCursor : TTaskCursorData }, IQueryVariables > = gql`
    query GetTasks (
        $text    : String
        $where   : QueryTasksCursorWhereWhereConditions
        $orderBy : [QueryTasksCursorOrderByOrderByClause!]
        $after   : String
    ){
        tasksCursor(
            text    : $text
            where   : $where
            orderBy : $orderBy
            first   : 50
            after   : $after
        ) {
            pageInfo {
                hasNextPage
                startCursor
                endCursor
                total
                currentPage
            }
            edges {
                node{
                    id
                    title
                    stand_id
                    ee_id
                    status
                    importance
                    cad_number
                    type
                    creator_id
                    assigned_to_id
                    total_comment
                    description
                    relStand {
                        id
                        ee_id
                        company_name
                    }
                    relCreator {
                        id
                        name
                        surname
                    }
                    relAssignedTo {
                        id
                        name
                        surname
                    }
                    relCad {
                        id
                        ee_id
                        stand_id
                        task_id
                        cad_number
                        status
                        version
                        correction
                        file_path
                        created_at
                        updated_at
                    }
                    created_at
                    updated_at
                }
            }
        }
    }
`;


export const GET_TASK_COMMENTS: TypedDocumentNode<
        { taskCommentsCursor : TTaskCommentCursorData },
        IQueryVariables
    > = gql`
    query GetTaskComments (
        $text    : String
        $where   : QueryTaskCommentsCursorWhereWhereConditions
        $orderBy : [QueryTaskCommentsCursorOrderByOrderByClause!]
        $after   : String
    ){
        taskCommentsCursor(
            text    : $text
            where   : $where
            orderBy : $orderBy
            first   : 50
            after   : $after
        ) {
            pageInfo {
                hasNextPage
                startCursor
                endCursor
                total
                currentPage
            }
            edges {
                node{
                    id
                    task_id
                    author_id
                    description
                    relAuthor {
                        id
                        name
                        surname
                    }
                    relAttachments {
                        id
                        server_name
                        original_name
                        extension
                        title
                        description
                        path
                    }
                    created_at
                    updated_at
                }
            }
        }
    }
`;